import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import _ from 'lodash'
import axios from 'axios'

export const config = {
	apiKey: 'AIzaSyAvWgjNM3wFko7mxvbUIKWJsZU7RAUoh2g',
	authDomain: 'family-tree-ac223.firebaseapp.com',
	databaseURL: 'https://family-tree-ac223.firebaseio.com',
	projectId: 'family-tree-ac223',
	storageBucket: 'family-tree-ac223.appspot.com',
	messagingSenderId: '595663263335'
}

firebase.initializeApp(config)

export const authApp = firebase.initializeApp(config, 'authApp')
export const detachedAuth = authApp.auth()

export const fb = firebase
export const db = firebase.firestore()
export const auth = firebase.auth()
export const emailAuthProvider = firebase.auth.EmailAuthProvider
export const storage = firebase.storage().ref()

export const trackLink = async linkId => {
	const ip = await getIPAddress()

	if (firebase.auth().currentUser) {
		const userRef = await db.collection('tracks').doc(linkId).get()

		let increment = 1
		if (userRef.data() && userRef.data()[firebase.auth().currentUser.uid]) {
			increment = userRef.data()[firebase.auth().currentUser.uid].count + 1
		}

		if (!userRef.exists) {
			return db
				.collection('tracks')
				.doc(linkId)
				.set({
					[firebase.auth().currentUser.uid]: {
						ip: ip,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						count: increment
					}
				})
		} else {
			return db
				.collection('tracks')
				.doc(linkId)
				.update({
					[firebase.auth().currentUser.uid]: {
						ip: ip,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						count: increment
					}
				})
		}
	} else {
		const userIp = 'ip_' + ip.replace('.', '')

		const userRef = await db.collection('tracks').doc(linkId).get()

		let increment = 1
		if (userRef.data() && userRef.data()[userIp]) {
			increment = userRef.data()[userIp].count + 1
		}

		if (!userRef.exists) {
			return db
				.collection('tracks')
				.doc(linkId)
				.set({
					[userIp]: {
						ip: ip,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						count: increment
					}
				})
		} else {
			return db
				.collection('tracks')
				.doc(linkId)
				.update({
					[userIp]: {
						ip: ip,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						count: increment
					}
				})
		}
	}
}

export const getIPAddress = async () => {
	try {
		const data = await axios.get(
			'https://ip-api.io/json?api_key=e6685919-9f3a-4aac-b0df-cbbee8ece2b0'
		)
		return data.data.ip
	} catch (e) {
		throw e
	}
}

export const getTracks = async () => {
	const snapshot = await firebase.firestore().collection('tracks').get()

	const tracks = snapshot.docs.map(doc => {
		return {
			...{ users: _.map(_.toPairs(doc.data()), d => _.fromPairs([d])) },
			...{ id: doc.id }
		}
	})

	const newTracks = getTrackUsers(tracks)

	return newTracks
}

export const getTrackUsers = async tracks => {
	const usersId = []

	tracks.forEach(track => {
		track.users.forEach(user => {
			if (usersId.indexOf(Object.keys(user)[0]) === -1)
				usersId.push(Object.keys(user)[0])
		})
	})

	const rawUsers = []

	usersId.forEach(user => {
		rawUsers.push(db.collection('users').doc(user).get())
	})

	const fetchUsers = await Promise.all(rawUsers)

	const users = []

	fetchUsers.forEach(user => {
		users.push(user.data())
	})

	console.log(tracks)
	tracks.forEach(track => {
		track.users = track.users.map(user => {
			const data = users.find(u => {
				if (u) {
					return u.userId === Object.keys(user)[0]
				} else {
					return undefined
				}
			})
			if (data) {
				return (user = {
					userId: data.userId,
					email: data.email,
					deleted: data.deleted,
					info: Object.values(user)[0]
				})
			} else {
				return (user = {
					userId: '',
					email: 'Utilisateur Anonyme',
					deleted: false,
					info: '-'
				})
			}
		})
	})

	return tracks
}

export const getDocument = (path, url) => {
	return storage
		.child(`${path}/${url}`)
		.getDownloadURL()
		.then(url => {
			return url
		})
		.catch(e => {
			return ''
		})
}

export const getAllUsers = async () => {
	const snapshot = await firebase.firestore().collection('users').get()

	let users = snapshot.docs.map(doc => {
		return {
			...doc.data(),
			...{ id: doc.id }
		}
	})

	users = users.filter(user => !user.deleted)

	return users
}

export const deleteUser = async userId => {
	await firebase
		.firestore()
		.collection('users')
		.doc(userId)
		.update({ deleted: true })
}

export const getKml = async url => {
	const kml = await storage.child(`kml/${url}`).getDownloadURL()
	return kml
}

export const sendEmail = async (
	email,
	firstName,
	lastName,
	message,
	attachments
) => {
	return await firebase.firestore().collection('emails').add({
		email,
		firstName,
		lastName,
		message,
		attachments
	})
}

export const sendBatchEmails = async (subject, message) => {
	return await firebase.firestore().collection('batchEmails').add({
		subject,
		message
	})
}

export const getNewsText = async () => {
	const newsText = await firebase
		.firestore()
		.collection('news')
		.doc('main')
		.get()

	if (newsText.data()) return newsText.data().text
}

export const updateNewsText = async text => {
	return await firebase
		.firestore()
		.collection('news')
		.doc('main')
		.update({ text })
}

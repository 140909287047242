import React from 'react';
import { connect } from 'react-redux';

import Header from '../../components/Header/Header';
import ContactForm from '../../components/ContactForm/ContactForm';

class ContactPage extends React.Component {
	render() {
		return (
			<div className="contact-form">
				<Header />
				<ContactForm email={this.props.authEmail} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authEmail: state.auth.user.email
	};
};

export default connect(
	mapStateToProps,
	null
)(ContactPage);

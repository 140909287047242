import React from 'react';
import { compose, withProps } from 'recompose';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	StreetViewPanorama,
	OverlayView,
	Marker
} from 'react-google-maps';

import getLocationByAddress from '../api/google-maps';
import Spinner from './Spinner';

const getPixelPositionOffset = (width, height) => ({
	x: -(width / 2),
	y: -(height / 2)
});

const getMapView = props => {
	if (props.street) {
		return (
			<GoogleMap defaultZoom={14} defaultCenter={props.coords}>
				<StreetViewPanorama defaultPosition={props.coords} visible>
					<OverlayView
						position={props.coords}
						mapPaneName={OverlayView.OVERLAY_LAYER}
						getPixelPositionOffset={getPixelPositionOffset}
					>
						<div
							style={{
								background: `red`,
								color: `white`,
								padding: 5,
								borderRadius: `50%`
							}}
						>
							OverlayView
						</div>
					</OverlayView>
				</StreetViewPanorama>
			</GoogleMap>
		);
	} else {
		return (
			<GoogleMap defaultZoom={14} defaultCenter={props.coords}>
				{props.isMarkerShown && <Marker position={props.coords} />}
			</GoogleMap>
		);
	}
};

const MapComponent = compose(
	withProps({
		googleMapURL:
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKBZoWLLha6GhccLZNBASkHlfnD-sPpjk&v=3.exp&libraries=geometry,drawing,places',
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100vh` }} />,
		mapElement: <div style={{ height: `100%` }} />,
		center: { lat: 2.3522219, lng: 48.856614 }
	}),
	withScriptjs,
	withGoogleMap
)(props => getMapView(props));

class MapContainer extends React.PureComponent {
	state = {
		isMarkerShown: false,
		coords: {}
	};

	componentDidMount() {
		this.delayedShowMarker();
		this.getMarker();
	}

	delayedShowMarker = () => {
		setTimeout(() => {
			this.setState({ isMarkerShown: true });
		}, 500);
	};

	getMarker = async () => {
		const coords = await getLocationByAddress(this.props.address);
		this.setState({ coords: coords });
	};

	render() {
		if (!this.state.coords.lat) return <Spinner />;

		return (
			<div>
				<MapComponent
					isMarkerShown={this.state.isMarkerShown}
					coords={this.state.coords}
					street={this.props.street}
				/>
			</div>
		);
	}
}

export default MapContainer;

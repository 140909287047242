import React from 'react';
import PdfLink from '../../../components/PdfLink';

import documentsData from '../../../data/documentsData';

const Illig = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().illig[0].title}
				path={documentsData().illig[0].path}
				url={documentsData().illig[0].url}
				id={documentsData().illig[0].id}
			/>
		</div>
	);
};

export default Illig;

import './Diagram.css';

import React from 'react';
import { connect } from 'react-redux';
import { logInOpen } from '../../actions/auth';
import Graph from 'react-graph-vis';
import history from '../../history';

import familiesData from '../../data/familiesData';

class Diagram extends React.Component {
	render() {
		const graph = {
			nodes: familiesData(),
			edges: [
				{ from: 'dupont', to: 'urbain' },
				{ from: 'dupont', to: 'capey' },
				{ from: 'dupont', to: 'beckx' },
				{ from: 'dupont', to: 'fontaine' },
				{ from: 'fontaine', to: 'toutard' },
				{ from: 'dupont', to: 'gervaiseau' },
				{ from: 'gervaiseau', to: 'salvage' },
				{ from: 'salvage', to: 'potut' },
				{ from: 'salvage', to: 'coulmeau' },
				{ from: 'salvage', to: 'illig' },
				{ from: 'salvage', to: 'zele' },
				{ from: 'salvage', to: 'gerouit' }
			]
		};

		const options = {
			nodes: {
				shape: 'circle',
				widthConstraint: {
					minimum: 150
				},
				font: {
					face: 'Quicksand',
					strokeColor: '#030442',
					strokeWidth: 1,
					size: 24,
					color: '#030442'
				}
			},
			// layout: {
			// 	hierarchical: {
			// 		levelSeparation: 220,
			// 		nodeSpacing: 200
			// 		// sortMethod: 'directed'
			// 	}
			// },
			interaction: {
				dragView: false,
				zoomView: false,
				dragNodes: false,
				tooltipDelay: 0
			},
			physics: {
				enabled: false
			},
			height: window.document.body.offsetHeight - 180 + 'px'
		};

		const events = {
			select: event => {
				if (event.nodes[0]) history.push(`/famille/${event.nodes[0]}`);
			}
		};

		return <Graph graph={graph} options={options} events={events} />;
	}
}

const mapStateToProps = state => {
	return { isSignedIn: state.auth.isSignedIn };
};

export default connect(
	mapStateToProps,
	{ logInOpen }
)(Diagram);

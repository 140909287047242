import React from 'react';
import PdfLink from '../../../components/PdfLink';

import documentsData from '../../../data/documentsData';
import CivilAct from '../CivilAct'

const Beckx = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().beckx[0].title}
				path={documentsData().beckx[0].path}
				url={documentsData().beckx[0].url}
				id={documentsData().beckx[0].id}
			/>
			<br />

			<CivilAct path="beckx" />
		</div>
	);
};

export default Beckx;

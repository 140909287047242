const Documents = () => {
	return {
		dupont: [
			{
				id: 'dupont1',
				title: 'Dupont 1719 – 1902',
				path: 'dupont/tree',
				url: '10_Arbre Genealogique Famille Dupont 1719-1902.pdf'
			},
			{
				id: 'dupont2',
				title: 'Dupont 1828 – 1987',
				path: 'dupont/tree',
				url: '14_Arbre Genealogique Famille Dupont 1828-1987.pdf'
			},
			{
				id: 'dupont4',
				title: 'Dupont Thérèse',
				path: 'dupont/tree',
				url: '16_Arbre Genealogique Therese Dupont.pdf'
			},
			{
				id: 'dupont5',
				title: 'Dupont Louis',
				path: 'dupont/tree',
				url: '17_Arbre Genealogique Louis Dupont.pdf'
			},
			{
				id: 'dupont6',
				title: 'Dupont Jacques',
				path: 'dupont/tree',
				url: '18_Arbre Genealogique Jacques Dupont.pdf'
			}
		],
		salvage: [
			{
				id: 'salvage2',
				title: 'Salvage 1682 – 1875',
				path: 'salvage/tree',
				url: '23_Arbre Genealogique Famille Salvage 1682-1875.pdf'
			},
			{
				id: 'salvage3',
				title: 'Salvage Guillaume Henri',
				path: 'salvage/tree',
				url: '25_Arbre Genealogique Guillaume Henri Salvage.pdf'
			},
			{
				id: 'salvage5',
				title:
					'Salvage Pierre Louis et Anna Salvage famille Gérouit Illig Zèle',
				path: 'salvage/tree',
				url: '26_Arbre Genealogique Pierre Louis et Anna Salvage Familles Gerouit Illig Zele.pdf'
			},
			{
				id: 'salvage6',
				title: 'Salvage Marie famille Bodin Girardeau',
				path: 'salvage/tree',
				url: '28_Arbre Genealogique Marie Salvage Familles Bodin Girardeau.pdf'
			},
			{
				id: 'salvage7',
				title: 'Salvage Antoinette famille Ortholanis',
				path: 'salvage/tree',
				url: '29_Arbre Genealogique Marie Salvage Famille Ortholanis.pdf'
			}
		],
		beckx: [
			{
				id: 'beckx1',
				title: 'Beckx Louisa',
				path: 'beckx/tree',
				url: '65_Arbre Genealogique Louisa Beckx.pdf'
			}
		],
		capey: [
			{
				id: 'capey1',
				title: 'Capey Thérèse',
				path: 'capey/tree',
				url: '32_Arbre Genealogique Therese Capey.pdf'
			}
		],
		coulmeau: [
			{
				id: 'coulmeau1',
				title: 'Coulmeau Eugénie',
				path: 'coulmeau/tree',
				url: '85_Arbre Genealogique Eugenie Coulmeau.pdf'
			},
			{
				id: 'coulmeau2',
				title: 'Coulmeau Eugénie - Fauconnet',
				path: 'coulmeau/tree',
				url: '87_Arbre Genealogique Eugenie Coulmeau Famille Fauconnet.pdf'
			},
			{
				id: 'coulmeau3',
				title: 'Coulmeau Eugénie - Garreau Venot',
				path: 'coulmeau/tree',
				url: '88_Arbre Genealogique Eugenie Coulmeau Familles Garreau Venot.pdf'
			}
		],
		fontaine: [
			{
				id: 'fontaine1',
				title: 'Fontaine Marcel',
				path: 'fontaine/tree',
				url: '75_Arbre Genealogique Marcel Fontaine.pdf'
			}
		],
		gerouit: [
			{
				id: 'gerout1',
				title:
					'Salvage Pierre Louis et Anna Salvage famille Gérouit Illig Zèle',
				path: 'salvage/tree',
				url: '26_Arbre Genealogique Pierre Louis et Anna Salvage Familles Gerouit Illig Zele.pdf'
			}
		],
		gervaiseau: [
			{
				id: 'gervaiseau2',
				title: 'Gervaiseau Léon',
				path: 'gervaiseau/tree',
				url: '44_Arbre Genealogique Leon Gervaiseau.pdf'
			}
		],
		illig: [
			{
				id: 'illig1',
				title:
					'Salvage Pierre Louis et Anna Salvage famille Gérouit Illig Zèle',
				path: 'salvage/tree',
				url: '26_Arbre Genealogique Pierre Louis et Anna Salvage Familles Gerouit Illig Zele.pdf'
			}
		],
		potut: [
			{
				id: 'potut1',
				title: 'Potut Gaston',
				path: 'potut/tree',
				url: '55_Arbre Genealogique Gaston Potut.pdf'
			}
		],
		toutard: [
			{
				id: 'toutard1',
				title: 'Toutard André',
				path: 'toutard/tree',
				url: '95_Arbre Genealogique Andre Toutard.pdf'
			}
		],
		urbain: [
			{
				id: 'urbain1',
				title: 'Urbain Hortense',
				path: 'urbain/tree',
				url: '35_Arbre Genealogique Hortense Urbain.pdf'
			}
		],
		zele: [
			{
				id: 'toutard1',
				title:
					'Salvage Pierre Louis et Anna Salvage famille Gérouit Illig Zèle',
				path: 'salvage/tree',
				url: '26_Arbre Genealogique Pierre Louis et Anna Salvage Familles Gerouit Illig Zele.pdf'
			}
		]
	}
}

export default Documents

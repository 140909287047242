import React from 'react';

import MapContainer from '../components/Map';

const MapPage = props => {
	if (props.match) {
		const locationId = props.match.params.locationId.split('&')[0];
		const street = props.match.params.locationId.split('&')[1] === 'street';

		return (
			<div>
				<MapContainer address={locationId} street={street} />
			</div>
		);
	} else {
		return <></>;
	}
};

export default MapPage;

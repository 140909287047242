import { db, auth } from '../components/Firebase/Firebase';

import {
	SIGN_IN_OPEN,
	SIGN_IN_CLOSE,
	LOG_IN_OPEN,
	LOG_IN_CLOSE,
	SIGN_IN_START,
	SIGN_IN_ERROR,
	SIGN_IN,
	SIGN_OUT,
	FETCH_CURRENT_USER
} from './types';

export const signInOpen = () => {
	return {
		type: SIGN_IN_OPEN
	};
};

export const signInClose = () => {
	return {
		type: SIGN_IN_CLOSE
	};
};

export const logInOpen = () => {
	return {
		type: LOG_IN_OPEN
	};
};

export const logInClose = () => {
	return {
		type: LOG_IN_CLOSE
	};
};

export const signInStart = () => {
	return {
		type: SIGN_IN_START
	};
};

export const signInError = message => {
	return {
		type: SIGN_IN_ERROR,
		payload: message
	};
};

export const signIn = (authInfo, admin) => async dispatch => {
	let user = await db
		.collection('users')
		.doc(authInfo.userId)
		.get();

	if (!user.exists) {
		await db
			.collection('users')
			.doc(authInfo.userId)
			.set(authInfo);
	}

	if (!admin) dispatch(fetchCurrentUser(authInfo.userId));

	dispatch({
		type: SIGN_IN
	});
};

export const signOut = userId => {
	auth.signOut();

	return {
		type: SIGN_OUT,
		payload: userId
	};
};

export const fetchCurrentUser = userId => async dispatch => {
	const user = await db
		.collection('users')
		.doc(userId)
		.get();

	dispatch({
		type: FETCH_CURRENT_USER,
		payload: user.data()
	});
};

export const updatePassword = newPassword => async dispatch => {
	return await auth.currentUser.updatePassword(newPassword);
};

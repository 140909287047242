const Documents = () => {
	return [
		{
			url: 'D18160124_Fontaine louis etienne francois Guillot louise eugenie acte de mariage Compiegne 60.jpg',
			lastName: 'Fontaine',
			firstName: 'Louis Etienne François',
			type: 'marriage',
			year: '1816/01/24',
			location: 'Compiègne'
		},
		{
			url: 'D18160124_Guillot louise eugenie Fontaine louis etienne francois acte de mariage Compiegne 60.jpg',
			lastName: 'Guillot',
			firstName: 'Louise Eugénie',
			type: 'marriage',
			year: '1816/01/24',
			location: 'Compiègne'
		},
		{
			url: 'D18170619_Fontaine etienne laurent acte de naissance Compiegne 60.jpg',
			lastName: 'Fontaine',
			firstName: 'Etienne Laurent',
			type: 'birth',
			year: '1817/06/19',
			location: 'Compiègne'
		},
		{
			url: 'D18480301_Fontaine jules acte de naissance Douai 59.jpg',
			lastName: 'Fontaine',
			firstName: 'Jules',
			type: 'birth',
			year: '1848/03/01',
			location: 'Douai'
		},
		{
			url: 'D18490519_Fontaine leonie mathilde acte de naissance Douai 59.jpg',
			lastName: 'Fontaine',
			firstName: 'Léonie Mathilde',
			type: 'birth',
			year: '1849/05/19',
			location: 'Douai'
		},
		{
			url: 'D18530413_Fontaine georges alexandre cesar acte de naissance Paris 15.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César',
			type: 'birth',
			year: '1853/04/13',
			location: 'Paris 15'
		},
		{
			url: 'D18441022_Fontaine etienne laurent Omont Heloise Hermance acte de mariage Douai 59.jpg',
			lastName: 'Fontaine',
			firstName: 'Etienne Laurent',
			type: 'marriage',
			year: '1844/10/22',
			location: 'Douai'
		},
		{
			url: 'D18441022_Homont heloise hermance Fontaine etienne laurent acte de mariage Douai 59.jpg',
			lastName: 'Homont',
			firstName: 'Héloise Hermance',
			type: 'marriage',
			year: '1844/10/22',
			location: 'Douai'
		},
		{
			url: 'D18681115_Fontaine louis etienne Francois acte de deces Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Louis Etienne François',
			type: 'death',
			year: '1868/11/15',
			location: 'Paris 18'
		},
		{
			url: 'D18791004_Fontaine georges alexandre Soret eugenie olympe alphonsine acte de mariage Paris 15.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César',
			type: 'marriage',
			year: '1879/10/04',
			location: 'Paris 15'
		},
		{
			url: 'D18791004_Soret eugenie olympe alphonsine Fontaine georges alexandre acte de mariage Paris 15.jpg',
			lastName: 'Soret',
			firstName: 'Eugénie Olympe Alphonsine',
			type: 'marriage',
			year: '1879/10/04',
			location: 'Paris 15'
		},
		{
			url: 'D19370119_Fontaine georges alexandre cesar acte de deces Paris 10.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César',
			type: 'death',
			year: '1937/01/19',
			location: 'Paris 10'
		},
		{
			url: 'D19540511_Fontaine marcel louis edouard acte de deces Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Marcel Louis Edouard',
			type: 'death',
			year: '1954/05/11',
			location: 'Paris 18'
		},
		{
			url: 'D17601001_Fontaine etienne georges joseph acte Fiche Compiegne 60.pdf',
			lastName: 'Fontaine',
			firstName: 'Étienne Georges Joseph',
			type: 'fiche',
			year: '1760/01/01',
			location: 'Compiègne'
		},
		{
			url: 'D18170619_Fontaine etienne laurent acte fiche Compiegne 60.pdf',
			lastName: 'Fontaine',
			firstName: 'Étienne Laurent',
			type: 'fiche',
			year: '1817/06/19',
			location: 'Compiègne'
		},
		{
			url: 'D18530413_Fontaine georges alexandre cesar acte fiche Paris 15.pdf',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César',
			type: 'fiche',
			year: '1853/04/13',
			location: 'Douai'
		},
		{
			url: 'D17880920_Fontaine louis etienne francois acte fiche Compiegne 60.pdf',
			lastName: 'Fontaine',
			firstName: 'Louis Etienne François',
			type: 'fiche',
			year: '1788/09/20',
			location: 'Compiègne'
		},
		{
			url: 'D18930404_Fontaine marcel louis edouard acte fiche Dunkerque 59.pdf',
			lastName: 'Fontaine',
			firstName: 'Marcel Louis Édouard',
			type: 'fiche',
			year: '1893/04/04',
			location: 'Dunkerque'
		},
		{
			url: 'D17941026_Guillot jean rene Ge marie genevieve charlotte acte de mariage Compiegne 60.jpg',
			lastName: 'Guillot',
			firstName: 'Jean René',
			type: 'marriage',
			year: '1794/10/26',
			location: 'Compiègne'
		},
		{
			url: 'D17941026_Ge marie genevieve charlotte Guillot jean rene acte de mariage Compiegne 60.jpg',
			lastName: 'Gé',
			firstName: 'Marie Geneviève',
			type: 'marriage',
			year: '1794/10/26',
			location: 'Compiègne'
		},
		{
			url: 'D18420115_Fontaine georges joseph etienne acte de deces Compiegne 60.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Joseph Etienne',
			type: 'death',
			year: '1842/01/15',
			location: 'Compiègne'
		},
		{
			url: 'D18540509_Courly alexandrine therese Fontaine Louis etienne francois acte de mariage Paris.jpg',
			lastName: 'Courly',
			firstName: 'Alexandrine Thérèse',
			type: 'marriage',
			year: '1854/05/09',
			location: 'Paris'
		},
		{
			url: 'D18540509_Fontaine louis etienne francois Courly alexandrine therese acte de mariage Paris.jpg',
			lastName: 'Fontaine',
			firstName: 'Louis Etienne François',
			type: 'marriage',
			year: '1854/05/09',
			location: 'Paris'
		},
		{
			url: 'D18920704_Fontaine georges alexandre cesar Vincent marie pauline acte de mariage Paris 15.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César Vincent',
			type: 'marriage',
			year: '1892/07/04',
			location: 'Paris 15'
		},
		{
			url: 'D18920704_Vincent marie pauline Fontaine georges alexandre cesar acte de mariage Paris 15.jpg',
			lastName: 'Vincent',
			firstName: 'Marie Pauline',
			type: 'marriage',
			year: '1892/07/04',
			location: 'Paris 15'
		},
		{
			url: 'D18841005_Fontaine etienne laurent acte de deces Vanves 92.jpg',
			lastName: 'Fontaine',
			firstName: 'Etienne Laurent',
			type: 'death',
			year: '1884/10/05',
			location: 'Vanves'
		},
		{
			url: 'D18930404_Fontaine marcel louis edouard acte de naissance Dunkerque 59.jpg',
			lastName: 'Fontaine',
			firstName: 'Louis Edouard',
			type: 'death',
			year: '1893/04/04',
			location: 'Dunkerque'
		},
		{
			url: 'D19130820_De Resener jeanne Fontaine georges alexandre cesar acte de mariage Paris 15.jpg',
			lastName: 'De Resener',
			firstName: 'Jeanne',
			type: 'marriage',
			year: '1913/08/20',
			location: 'Paris 15'
		},
		{
			url: 'D19130820_Fontaine georges alexandre cesar de Resener jeanne acte de mariage Paris 15.jpg',
			lastName: 'Fontaine',
			firstName: 'Georges Alexandre César',
			type: 'marriage',
			year: '1913/08/20',
			location: 'Paris 18'
		},
		{
			url: 'D19200616_Dupont therese lucie Fontaine marcel louis edouard acte de mariage Paris 18.jpg',
			lastName: 'Dupont',
			firstName: 'Thérèse Lucie',
			type: 'marriage',
			year: '1920/06/16',
			location: 'Paris 18'
		},
		{
			url: 'D19200616_Fontaine marcel louis edouard Dupont therese lucie acte de mariage Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Marcel Louis Edouard',
			type: 'marriage',
			year: '1920/06/16',
			location: 'Paris 18'
		},
		{
			url: 'D19200616_Dupont therese Fontaine marcel acte photo Paris 18.jpg',
			lastName: 'Dupont Fontaine',
			firstName: 'Marcel Thérèse',
			type: 'simple_photo',
			year: '1920/06/16',
			location: 'Paris 18'
		},
		{
			url: 'D19290630_Dupont fernand Capey therese Fontaine jacques acte photo.jpg',
			lastName: 'Dupont Capey Fontaine',
			firstName: 'Fernand Thérèse Jacques(Kako)',
			type: 'simple_photo',
			year: '1920/06/30'
		},
		{
			url: 'D19310630_Fontaine marcel jacques acte photo Paris.jpg',
			lastName: 'Fontaine',
			firstName: 'Marcel Jacques(Kako)',
			type: 'simple_photo',
			year: '1931/06/30',
			location: 'Paris'
		},
		{
			url: 'D19330312_Fontaine jacques acte photo Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Jacques(Kako)',
			type: 'simple_photo',
			year: '1933/03/12',
			location: 'Paris 18'
		},
		{
			url: 'D19390630_Fontaine jacques acte photo confirmation Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Jacques(Kako)',
			type: 'confirmation_photo',
			year: '1939/06/30',
			location: 'Paris 18'
		},
		{
			url: 'D19470601_Fontaine jacques acte photo militaire Rouen 76.jpg',
			lastName: 'Fontaine',
			firstName: 'Jacques(Kako)',
			type: 'soldier_military',
			year: '1947/06/01',
			location: 'Rouen 76'
		},
		{
			url: 'D19530630_Fontaine jacques odile catherine acte photo Bourg-la-Reine 92.jpg',
			lastName: 'Fontaine',
			firstName: 'Jacques(Kako) Odile Catherine',
			type: 'simple_photo',
			year: '1953/06/30',
			location: 'Bourg-la-Reine 92'
		},
		{
			url: 'D19590528_Dupont michel andre claire Fontaine odile catherine jean-marc beatrice Magny brigitte jean-louis Lecaudey gerard Paris 01.jpg',
			lastName: 'Dupont Fontaine Magny Lecaudey',
			firstName:
				'Michel André Claire Odile Catherine Jean-Marc Béatrice Brigitte Jean-Louis Gérard',
			type: 'confirmation_photo',
			year: '1959/05/28',
			location: 'Paris 01'
		},
		{
			url: 'D19630115_Dupont therese andre claire Fontaine jacques kako odile Catherine beatrice jean-Marc Toutard genevieve acte photo Paris 18.jpg',
			lastName: 'Dupont Fontaine Toutard',
			firstName:
				'Thérèse André Jacques(Kako) Odile Catherine Béatrice Jean-Marc Geneviève',
			type: 'simple_photo',
			year: '1963/01/15',
			location: 'Paris 18'
		},
		{
			url: 'D19630630_fontaine odile catherine acte photo confirmation Paris 18.jpg',
			lastName: 'Fontaine',
			firstName: 'Odile Catherine',
			type: 'confirmation_photo',
			year: '1963/06/30',
			location: 'Paris 18'
		},
		{
			url: 'D19710815_Dupont thérèse Fontaine beatrice acte photo monastere Sainte Claire Paray-le-Moniale 71.jpg',
			lastName: 'Dupont Fontaine',
			firstName: 'Thérèse Béatrice',
			type: 'simple_photo',
			year: '1971/08/15',
			location: 'Paray-le-Moniale 71'
		},
		{
			url: 'D19730630_Dupont therese Fontaine jacques jean-marc marie-christine jean-paul acte photo Paris 18.jpg',
			lastName: 'Dupont Fontaine',
			firstName: 'Thérèse Jacques(Kako) Jean-Marc Marie-Christine Jean-Paul',
			type: 'simple_photo',
			year: '1973/06/30',
			location: 'Rue Véron Paris 18'
		},
		{
			url: 'D19941030_Famille Dupont Fontaine Jacques Toutard genevieve acte photo 80 ans leone sur la Seine Paris 01.jpg',
			lastName: 'Dupont Fontaine Toutard',
			firstName: 'Léone(80 ans) Jacques(Kako) Geneviève',
			type: 'birthday_photo',
			year: '1994/10/30',
			location: 'Rue Véron Paris 01'
		},
		{
			url: 'D20191006_Fontaine jean-paul  catherine jean-marc beatrice marie-christine acte photo anniversaire Paris 18.mp4',
			lastName: 'Fontaine',
			firstName: 'Jean-Paul(64 ans) Catherine Béatrice Marie-Christine',
			type: 'birthday_photo',
			year: '2019/10/06',
			location: 'Paris 18'
		},
		{
			url: 'D20191006_Fontaine jean-paul jean_marc Toutard genevieve Chouaki Sophie acte photo anniversaire Paris 18.mp4',
			lastName: 'Fontaine Toutard Chouaki',
			firstName: 'Jean-Paul(64 ans) Geneviève Sophie',
			type: 'birthday_photo',
			year: '2019/10/06',
			location: 'Paris 18'
		}
	]
}

export default Documents

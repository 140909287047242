import React from 'react';
import PdfLink from '../../../components/PdfLink';
import CivilAct from '../CivilAct';

import documentsData from '../../../data/documentsData';

const Urbain = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().urbain[0].title}
				path={documentsData().urbain[0].path}
				url={documentsData().urbain[0].url}
				id={documentsData().urbain[0].id}
			/>

			<br />

			<CivilAct path="urbain" />
		</div>
	);
};

export default Urbain;

import React from 'react'
import PdfLink from '../../../components/PdfLink'
import CivilAct from '../CivilAct'

import documentsData from '../../../data/documentsData'

const Salvage = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().salvage[0].title}
				path={documentsData().salvage[0].path}
				url={documentsData().salvage[0].url}
				id={documentsData().salvage[0].id}
			/>
			<PdfLink
				title={documentsData().salvage[1].title}
				path={documentsData().salvage[1].path}
				url={documentsData().salvage[1].url}
				id={documentsData().salvage[1].id}
			/>
			<PdfLink
				title={documentsData().salvage[2].title}
				path={documentsData().salvage[2].path}
				url={documentsData().salvage[2].url}
				id={documentsData().salvage[2].id}
			/>

			<PdfLink
				title={documentsData().salvage[3].title}
				path={documentsData().salvage[3].path}
				url={documentsData().salvage[3].url}
				id={documentsData().salvage[3].id}
			/>
			<PdfLink
				title={documentsData().salvage[4].title}
				path={documentsData().salvage[4].path}
				url={documentsData().salvage[4].url}
				id={documentsData().salvage[4].id}
			/>

			<br />

			<CivilAct path="salvage" />
		</div>
	)
}

export default Salvage

import {
	SIGN_IN_OPEN,
	SIGN_IN_CLOSE,
	LOG_IN_OPEN,
	LOG_IN_CLOSE,
	SIGN_IN_START,
	SIGN_IN_ERROR,
	SIGN_IN,
	SIGN_OUT,
	FETCH_CURRENT_USER
} from '../actions/types';

const INITIAL_STATE = {
	openLoginModal: null,
	openAuthModal: null,
	isSignedIn: null,
	loadingAuth: null,
	errorMessage: null,
	user: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIGN_IN_OPEN:
			return { ...state, openAuthModal: true };
		case SIGN_IN_CLOSE:
			return { ...state, openAuthModal: null };
		case SIGN_IN_START:
			return { ...state, loadingAuth: true };
		case SIGN_IN_ERROR:
			return { ...state, errorMessage: action.payload, loadingAuth: false };
		case SIGN_IN:
			return {
				...state,
				isSignedIn: true,
				loadingAuth: false,
				errorMessage: null
			};
		case SIGN_OUT:
			return { ...state, isSignedIn: false, user: {} };
		case LOG_IN_OPEN:
			return { ...state, openLoginModal: true };
		case LOG_IN_CLOSE:
			return { ...state, openLoginModal: null };
		case FETCH_CURRENT_USER:
			return {
				...state,
				loadingAuth: false,
				isSignedIn: true,
				errorMessage: null,
				openAuthModal: null,
				openLoginModal: null,
				user: { ...action.payload }
			};
		default:
			return state;
	}
};

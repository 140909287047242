import React from 'react';
import PdfLink from '../../../components/PdfLink';
import CivilAct from '../CivilAct';

import documentsData from '../../../data/documentsData';

const Fontaine = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().fontaine[0].title}
				path={documentsData().fontaine[0].path}
				url={documentsData().fontaine[0].url}
				id={documentsData().fontaine[0].id}
			/>

			<br />

			<CivilAct path="fontaine" />
		</div>
	);
};

export default Fontaine;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Router, Route, Link } from 'react-router-dom'
import history from '../history'
import moment from 'moment'

import { auth } from '../components/Firebase/Firebase'
import { connect } from 'react-redux'
import { signIn, signOut, logInOpen } from '../actions/auth'
import { getDocument, trackLink } from './Firebase/Firebase'

import Dashboard from '../pages/Dashboard/Dashboard'
import ContactPage from '../pages/ContactPage/ContactPage'
import LandingPage from '../pages/LandingPage/LandingPage'
import FamilyTree from '../pages/FamilyTree'
import ActsTable from '../pages/acts/ActsTable'
import MapPage from '../pages/MapPage'
import FamilyMap from '../components/FamilyMap/FamilyMap'
import familiesData from '../data/familiesData'
import documentsData from '../data/documentsData'
import PdfLink from './PdfLink'
import CivilAct from '../pages/familyDetail/CivilAct'
import LoginModal from './auth/LoginModal'

import './App.css'

class App extends React.Component {
	state = { openMenu: false, url: '' }

	componentDidMount() {
		moment.updateLocale('fr', {
			months:
				'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
					'_'
				),
			monthsShort:
				'Janv_Févr_Mars_Avr_Mai_Juin_Juil_Août_Sept_Oct_Nov_Déc'.split('_'),
			monthsParseExact: true,
			weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split(
				'_'
			),
			weekdaysShort: 'Dim_Lun_Mar_Mer_Jeu_Ven_Sam'.split('_')
		})

		moment.locale('fr')

		auth.onAuthStateChanged(async user => {
			if (user) {
				this.props.signIn({ userId: user.uid })

				const document = await getDocument(
					'documents',
					'ARBRE GENEALOGIQUE TOUTES FAMILLES.xlsm'
				)
				this.setState({ url: document })
			} else {
				this.props.signOut()
			}
		})
	}

	openLogin() {
		this.closeMenu()
		this.props.logInOpen()
	}

	doLogout() {
		this.closeMenu()
		this.props.signOut()
	}

	closeMenu() {
		this.setState({ openMenu: false })
	}

	render() {
		return (
			<div>
				<div className="app-background" />

				<Router history={history}>
					<div
						className={`ui vertical inverted sidebar menu left ${
							this.state.openMenu ? 'visible' : ''
						}`}
					>
						<div className="item">
							<i className="close icon" onClick={() => this.closeMenu()} />

							<div className="menu">
								<Link
									to="/"
									className="item header"
									onClick={() => this.closeMenu()}
								>
									Accueil
								</Link>
							</div>

							{this.props.isSignedIn && (
								<div className="menu">
									<a className="item header" onClick={() => this.doLogout()}>
										Déconnexion
									</a>
								</div>
							)}

							{!this.props.isSignedIn && (
								<div className="menu">
									<a className="item header" onClick={() => this.openLogin()}>
										Connexion
									</a>
								</div>
							)}

							<div className="menu">
								<Link
									to="/carte"
									className="item header"
									onClick={() => this.closeMenu()}
								>
									Carte
								</Link>
							</div>

							<div className="menu">
								<a
									href={this.state.url}
									rel="noopener noreferrer"
									className="item header"
									onClick={() => trackLink('excel')}
								>
									Excel
								</a>
							</div>

							<div className="menu">
								<Link
									to="/contact"
									className="item header"
									onClick={() => this.closeMenu()}
								>
									Nous Contacter
								</Link>
							</div>

							{familiesData().map(family => {
								return (
									<div key={family.id} className="menu">
										<Link
											to={`/famille/${family.id}`}
											key={family.id}
											className="item header"
											onClick={() => this.closeMenu()}
										>
											{family.id}
										</Link>
										{documentsData()[family.id].map(doc => {
											return (
												<div key={doc.id} className="item text-ellipsis">
													<PdfLink
														title={doc.title}
														path={doc.path}
														url={doc.url}
														id={doc.id}
													/>
												</div>
											)
										})}
										{family.id !== 'beckx' &&
											family.id !== 'potut' &&
											family.id !== 'coulmeau' &&
											family.id !== 'illig' &&
											family.id !== 'zele' &&
											family.id !== 'gerouit' && (
												<div
													className="item text-ellipsis"
													onClick={() => this.closeMenu()}
												>
													<CivilAct path={family.id} />
												</div>
											)}
									</div>
								)
							})}
						</div>
					</div>

					<div className="pusher">
						<Link to="/" onClick={() => this.closeMenu()}>
							<img
								className="logo-header"
								src="/assets/imgs/flag.svg"
								alt="home"
							/>
						</Link>

						<a
							className="item"
							onClick={() => this.setState({ openMenu: !this.state.openMenu })}
						>
							<i className="sidebar icon sidebar-icon" />
						</a>

						{(this.props.currentUser.email === 'dupontantoine92@gmail.com' ||
							this.props.currentUser.email ===
								'andre.francois.dupont@gmail.com') && (
							<Route path="/dashboard" exact component={Dashboard} />
						)}

						<Route path="/" exact component={LandingPage} />

						<Route path="/contact" exact component={ContactPage} />

						<div>
							<Route path="/famille/:familyId" exact component={FamilyTree} />

							<Route
								path="/famille/:familyId/documents"
								exact
								component={ActsTable}
							/>

							<Route
								path="/famille/:familyId/documents/:locationId"
								exact
								component={MapPage}
							/>
						</div>

						<Route path="/carte" exact component={FamilyMap} />
						<LoginModal />
					</div>
				</Router>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { isSignedIn: state.auth.isSignedIn, currentUser: state.auth.user }
}

export default connect(mapStateToProps, { signIn, signOut, logInOpen })(App)

import React from 'react';
import { connect } from 'react-redux';
import {
	signInStart,
	fetchCurrentUser,
	signInError,
	logInClose,
	updatePassword
} from '../../actions/auth';
import { Button, Modal } from 'semantic-ui-react';
import { auth } from '../Firebase/Firebase';

import LoginForm from './LoginForm';

class LoginModal extends React.Component {
	closeModal = () => this.props.logInClose();

	onSubmit = async formValues => {
		this.props.signInStart();

		try {
			const authResult = await auth.signInWithEmailAndPassword(
				formValues.email,
				formValues.password
			);

			this.props.fetchCurrentUser(authResult.user.uid);

			if (formValues.newPassword)
				this.props.updatePassword(formValues.newPassword);
		} catch (error) {
			this.props.signInError(error.message);
		}
	};

	render() {
		return (
			<Modal size="tiny" open={this.props.openModal} onClose={this.closeModal}>
				<Modal.Content>
					<Modal.Description>
						<div className="ui basic segment">
							<LoginForm onSubmit={this.onSubmit} />
						</div>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={this.closeModal}>Annuler</Button>
					<Button
						form="createForm"
						color="blue"
						content="Se connecter"
						disabled={this.props.loadingAuth}
						loading={this.props.loadingAuth}
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = state => {
	return {
		loadingAuth: state.auth.loadingAuth,
		openModal: state.auth.openLoginModal
	};
};

export default connect(
	mapStateToProps,
	{ signInStart, fetchCurrentUser, signInError, logInClose, updatePassword }
)(LoginModal);

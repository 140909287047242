import React from 'react';
import { connect } from 'react-redux';
import {
	signInStart,
	signIn,
	signInError,
	signInClose
} from '../../actions/auth';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';
import { detachedAuth } from '../Firebase/Firebase';

import AuthForm from './AuthForm';

class AuthModal extends React.Component {
	closeModal = () => this.props.signInClose();

	onSubmit = async formValues => {
		this.props.signInStart();

		try {
			const authResult = await detachedAuth.createUserWithEmailAndPassword(
				formValues.email,
				formValues.password
			);

			const authInfo = {
				userId: authResult.user.uid,
				email: authResult.user.providerData[0].email
			};

			this.props.signIn(authInfo, true);

			this.closeModal();
		} catch (error) {
			this.props.signInError(error.message);
		}
	};

	render() {
		return (
			<TransitionablePortal
				open={this.props.openModal}
				onClose={this.closeModal}
				transition={{ animation: 'scale', duration: 500 }}
			>
				<Modal size="tiny" open={true}>
					<Modal.Header>Nouveau Compte</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<div className="ui basic segment">
								<AuthForm onSubmit={this.onSubmit} />
							</div>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.closeModal}>Annuler</Button>
						<Button
							form="createForm"
							color="blue"
							content="Créer"
							disabled={this.props.loadingAuth}
							loading={this.props.loadingAuth}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		);
	}
}

const mapStateToProps = state => {
	return {
		loadingAuth: state.auth.loadingAuth,
		openModal: state.auth.openAuthModal
	};
};

export default connect(
	mapStateToProps,
	{ signInStart, signIn, signInError, signInClose }
)(AuthModal);

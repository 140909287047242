import React from 'react';
import { Link } from 'react-router-dom';
import { sendEmail } from '../../components/Firebase/Firebase';
import { storage } from '../Firebase/Firebase';

class ContactForm extends React.Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		message: '',
		attachments: [],
		loadingFile: false,
		sending: false,
		success: false
	};

	onFormSubmit = async event => {
		event.preventDefault();

		this.setState({ sending: true });

		await sendEmail(
			this.state.email,
			this.state.firstName,
			this.state.lastName,
			this.state.message,
			this.state.attachments
		);

		this.setState({ sending: false });
		this.setState({ success: true });
	};

	selectFile = e => {
		this.setState({ loadingFile: true });

		let file = Array.from(e.target.files)[0];

		let localName = file.name.split('.')[0];
		let fileType = file.name.split('.')[1];

		let uploadFileRef = storage.child('emails/' + localName + '.' + fileType);

		uploadFileRef.put(file).then(() => {
			uploadFileRef.getDownloadURL().then(url => {
				this.setState({
					attachments: [
						{
							filename: localName + '.' + fileType,
							path: url
						}
					],
					loadingFile: false
				});
			});
		});
	};

	resetInput = () => {
		let fileInput = document.getElementById('doc');
		if (fileInput) fileInput.value = '';
	};

	deleteFile() {
		this.setState({ attachments: [] });
	}

	componentDidMount() {
		if (this.props.email) this.setState({ email: this.props.email });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.email !== this.props.email) {
			this.setState({ email: this.props.email });
		}
	}

	render() {
		if (!this.state.success) {
			return (
				<div>
					<div className="ui basic segment contact-header">
						Si vous avez des corrections, des propositions ou des
						pièces jointes à nous soumettre, merci de nous contacter à l'aide de
						ce formulaire
					</div>
					<form onSubmit={this.onFormSubmit} className="ui form container">
						<div className="field">
							<label>Email*</label>
							<div className="ui icon input">
								<input
									type="email"
									placeholder="Email"
									autoComplete="email"
									name="email"
									value={this.state.email}
									onChange={e => this.setState({ email: e.target.value })}
									required
								/>
							</div>
						</div>
						<div className="field">
							<label>Prénom*</label>
							<div className="ui icon input">
								<input
									type="text"
									placeholder="Prénom"
									autoComplete="nope"
									name="firstName"
									value={this.state.firstName}
									onChange={e => this.setState({ firstName: e.target.value })}
									required
								/>
							</div>
						</div>
						<div className="field">
							<label>Nom de Famille*</label>
							<div className="ui icon input">
								<input
									type="text"
									placeholder="Nom de Famille"
									autoComplete="nope"
									name="lastName"
									value={this.state.lastName}
									onChange={e => this.setState({ lastName: e.target.value })}
									required
								/>
							</div>
						</div>
						<div className="field">
							<label>Message*</label>
							<div className="ui icon input">
								<textarea
									type="text"
									placeholder="Entrez votre message"
									autoComplete="nope"
									name="message"
									value={this.state.message}
									onChange={e => this.setState({ message: e.target.value })}
									required
								/>
							</div>
						</div>

						<button
							type="button"
							className={`ui green button tiny ${
								this.state.loadingFile ? 'loading' : ''
							}`}
							style={{ marginBottom: '20px' }}
						>
							<label htmlFor="doc" onClick={this.resetInput()}>
								Joindre un fichier
							</label>
						</button>

						{this.state.attachments[0] && (
							<span style={{ paddingLeft: '5px' }}>
								{this.state.attachments[0].filename}
								<i
									className="trash alternate icon grey"
									style={{ paddingLeft: '5px' }}
									onClick={() => this.deleteFile()}
								/>
							</span>
						)}

						<div className="button-form">
							<button
								className="ui button primary"
								type="submit"
								disabled={this.state.sending}
							>
								Envoyer
							</button>
						</div>

						{this.state.sending && (
							<div>
								<div className="ui active loader" />
								<p />
							</div>
						)}
					</form>

					<input
						type="file"
						id="doc"
						className="input-file"
						onChange={this.selectFile}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<div className="form-success">
						Votre message a été envoyé, nous vous y répondrons dans les plus
						brefs délais
					</div>
					<div className="form-success">
						<Link to="/">
							<button
								className="ui button primary center aligned"
								style={{ margin: 'auto' }}
								type="submit"
								disabled={this.state.sending}
							>
								Retour à l'accueil
							</button>
						</Link>
					</div>
				</div>
			);
		}
	}
}

export default ContactForm;

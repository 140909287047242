import React from 'react';
import PdfLink from '../../../components/PdfLink';
import CivilAct from '../CivilAct';

import documentsData from '../../../data/documentsData';

const Toutard = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().toutard[0].title}
				path={documentsData().toutard[0].path}
				url={documentsData().toutard[0].url}
				id={documentsData().toutard[0].id}
			/>

			<br />

			<CivilAct path="toutard" />
		</div>
	);
};

export default Toutard;

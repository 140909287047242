import React from 'react';
import { getDocument, trackLink } from './Firebase/Firebase';

class PdfLink extends React.Component {
	state = { url: '', openUrl: '_blank' };

	componentDidMount() {
		if (this.props.url) this.getDocument();

		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) this.setState({ openUrl: '' });
	}

	getDocument = async () => {
		const document = await getDocument(this.props.path, this.props.url);
		this.setState({ url: document });
	};

	openDocument = () => {
		trackLink(this.props.id);
	};

	render() {
		if (!this.props.url) {
			return (
				<div>
					<i className="close right icon icon-color text-grey" />
					<span className="text-medium text-grey">{this.props.title}</span>
				</div>
			);
		} else {
			return (
				<div>
					<a
						href={this.state.url}
						target={this.state.openUrl}
						rel="noopener noreferrer"
						onClick={this.openDocument}
					>
						<i className="tree right icon icon-color" />
						<span className="text-medium">{this.props.title}</span>
					</a>
				</div>
			);
		}
	}
}

export default PdfLink;

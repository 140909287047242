import React from 'react'
import { connect } from 'react-redux'
import { logInOpen, signOut } from '../../actions/auth'
import { Link } from 'react-router-dom'
import {
	getNewsText,
	getDocument,
	trackLink
} from '../../components/Firebase/Firebase'

import Header from '../../components/Header/Header'
import SearchBar from '../../components/SearchBar/SearchBar'
import Diagram from '../../components/Diagram/Diagram'

class LandingPage extends React.Component {
	state = { url: '', text: '' }

	async componentDidMount() {
		const document = await getDocument(
			'documents',
			'ARBRE GENEALOGIQUE TOUTES FAMILLES.xlsm'
		)
		this.setState({ url: document })

		const text = await getNewsText()
		this.setState({ text })
	}

	render() {
		return (
			<div>
				<Header title="Bienvenue à Toutes et à Tous<br>Cousines, Cousins<br>Votre Généalogie" />

				{this.state.text && (
					<div>
						<div className="marquee">
							<span>{this.state.text}</span>
						</div>
						<div className="marquee marquee2">
							<span>{this.state.text}</span>
						</div>
					</div>
				)}

				<SearchBar />

				{(this.props.currentUser.email === 'dupontantoine92@gmail.com' ||
					this.props.currentUser.email ===
						'andre.francois.dupont@gmail.com') && (
					<Link to="/dashboard">
						<button className="ui green button dashboard-text">
							Dashboard
						</button>
					</Link>
				)}

				<Link to="/contact">
					<button className="ui red button contact-text">Nous Contacter</button>
				</Link>

				<a
					href={this.state.url}
					rel="noopener noreferrer"
					onClick={() => trackLink('excel')}
				>
					<img
						className="logo-excel"
						src="/assets/imgs/excel.svg"
						alt="excel"
					/>
				</a>

				<Link to="/carte">
					<img className="logo-map" src="/assets/imgs/map.png" alt="map" />
				</Link>

				<div className="diagram-container">
					<Diagram />
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isSignedIn: state.auth.isSignedIn,
		currentUser: state.auth.user
	}
}

export default connect(mapStateToProps, { logInOpen, signOut })(LandingPage)

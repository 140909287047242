const Documents = () => {
	return [
		{
			url: 'D18450301_Potut jean baptiste acte de naissance Vindelle 17.jpg',
			lastName: 'Potut',
			firstName: 'Jean Baptiste',
			type: 'birth',
			year: '1845/03/01',
			location: 'Vindelle 17'
		},
		{
			url: 'D18520828_Potut augustin francois acte de naissance Vindelle 17.jpg',
			lastName: 'Potut',
			firstName: 'Augustin François',
			type: 'birth',
			year: '1852/08/28',
			location: 'Vindelle 17'
		},
		{
			url: 'D18801216_Potut jean acte de deces Vindelle 17.jpg',
			lastName: 'Potut',
			firstName: 'Jean',
			type: 'death',
			year: '1880/12/16',
			location: 'Vindelle 17'
		},
		{
			url: 'D18900904_Potut gaston jean marcel acte de naissance Vindelle 17.jpg',
			lastName: 'Potut',
			firstName: 'Gaston Jean Marcel',
			type: 'birth',
			year: '1890/09/04',
			location: 'Vindelle'
		}
	]
}

export default Documents

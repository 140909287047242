import './Header.css';

import React from 'react';

const Header = props => {
	return (
		<div className="header-container">
			<div
				className="text-header"
				dangerouslySetInnerHTML={{ __html: props.title }}
			/>
		</div>
	);
};

export default Header;

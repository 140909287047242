import React from 'react'
import PdfLink from '../../../components/PdfLink'
import CivilAct from '../CivilAct'

import documentsData from '../../../data/documentsData'

const Coulmeau = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().coulmeau[0].title}
				path={documentsData().coulmeau[0].path}
				url={documentsData().coulmeau[0].url}
				id={documentsData().coulmeau[0].id}
			/>

			<PdfLink
				title={documentsData().coulmeau[1].title}
				path={documentsData().coulmeau[1].path}
				url={documentsData().coulmeau[1].url}
				id={documentsData().coulmeau[1].id}
			/>

			<PdfLink
				title={documentsData().coulmeau[2].title}
				path={documentsData().coulmeau[2].path}
				url={documentsData().coulmeau[2].url}
				id={documentsData().coulmeau[2].id}
			/>

			<br />
			<CivilAct path="coulmeau" />
		</div>
	)
}

export default Coulmeau

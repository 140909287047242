import React from 'react';
import PdfLink from '../../../components/PdfLink';
import CivilAct from '../CivilAct';

import documentsData from '../../../data/documentsData';

const Dupont = () => {
	return (
		<div>
			<div className="content-container">
				<PdfLink
					title={documentsData().dupont[0].title}
					path={documentsData().dupont[0].path}
					url={documentsData().dupont[0].url}
					id={documentsData().dupont[0].id}
				/>

				<PdfLink
					title={documentsData().dupont[1].title}
					path={documentsData().dupont[1].path}
					url={documentsData().dupont[1].url}
					id={documentsData().dupont[1].id}
				/>

				<br />
				<div className="text-medium">
					La descendance de Dupont Fernand et Capey Thérèse (1828 à nos jours)
				</div>
				<br />

				<PdfLink
					title={documentsData().dupont[2].title}
					path={documentsData().dupont[2].path}
					url={documentsData().dupont[2].url}
					id={documentsData().dupont[2].id}
				/>

				<PdfLink
					title={documentsData().dupont[3].title}
					path={documentsData().dupont[3].path}
					url={documentsData().dupont[3].url}
					id={documentsData().dupont[3].id}
				/>

				<PdfLink
					title={documentsData().dupont[4].title}
					path={documentsData().dupont[4].path}
					url={documentsData().dupont[4].url}
					id={documentsData().dupont[4].id}
				/>

				<br />
				<CivilAct path="dupont" />
			</div>

			<img src="/assets/imgs/adn.png" alt="adn" className="man-adn" />
		</div>
	);
};

export default Dupont;

import React from 'react';
import PdfLink from '../../../components/PdfLink';
import CivilAct from '../CivilAct';

import documentsData from '../../../data/documentsData';

const Gervaiseau = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().gervaiseau[0].title}
				path={documentsData().gervaiseau[0].path}
				url={documentsData().gervaiseau[0].url}
				id={documentsData().gervaiseau[0].id}
			/>

			<br />

			<CivilAct path="gervaiseau" />
		</div>
	);
};

export default Gervaiseau;

import React from 'react'
import PdfLink from '../../../components/PdfLink'
import CivilAct from '../CivilAct'

import documentsData from '../../../data/documentsData'

const Potut = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().potut[0].title}
				path={documentsData().potut[0].path}
				url={documentsData().potut[0].url}
				id={documentsData().potut[0].id}
			/>

			<br />

			<CivilAct path="potut" />
		</div>
	)
}

export default Potut

import axios from 'axios';

const getLocationByAddress = async address => {
	try {
		const data = await axios.get(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDKBZoWLLha6GhccLZNBASkHlfnD-sPpjk`
		);

		if (data.data.results[0]) return data.data.results[0].geometry.location;
	} catch (e) {
		throw e;
	}
};

export default getLocationByAddress;

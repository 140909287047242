const Documents = () => {
	return [
		{
			url: 'D18790719_Salvage guillaume henri acte livret miliaire Paris.pdf',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'military',
			year: '1879/07/01',
			location: 'Paris'
		},
		{
			url: 'D18830923_Salvage guillaume henri acte feuille de route Le Mans 72.pdf',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'military',
			typeExtra: 'feuille_de_route',
			year: '1883/09/23',
			location: 'Le Mans 72'
		},
		{
			url: 'D18830924_Salvage guillaume henri certificat de bonne conduite militaire Le Mans 72.pdf',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'military',
			typeExtra: 'certificat_de_bonne_conduite',
			year: '1883/09/24',
			location: 'Le Mans 72'
		},
		{
			url: 'D18850510_Couleau eugenie clementine Salvage guillaume henri acte publication des bans Paris 01.jpg',
			lastName: 'Coulmeau',
			firstName: 'Eugénie Clémentine',
			type: 'bans_marriage',
			year: '1885/05/10',
			location: 'Paris 01'
		},
		{
			url: 'D18850510_Salvage guillaume henri Couleau eugenie clementine acte publication des bans Paris 01.jpg',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'bans_marriage',
			year: '1885/05/10',
			location: 'Paris 01'
		},
		{
			url: 'D18850528_Couleau eugenie clementine Salvage guillaume henri acte livret de famille Saint Leonard 76.jpg',
			lastName: 'Coulmeau',
			firstName: 'Eugénie Clémentine',
			type: 'family',
			year: '1885/05/28',
			location: 'Saint Léonard 76'
		},
		{
			url: 'D18850528_Salvage guillaume henri Couleau eugenie clementine acte livret de famille Saint Leonard  76.jpg',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'family',
			year: '1885/05/28',
			location: 'Saint Léonard 76'
		},
		{
			url: 'D18900224_Salvage marguerite elisabeth acte de naissance Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'birth',
			year: '1890/02/24',
			location: 'Paris 05'
		},
		{
			url: 'D18900803_Salvage marguerite acte extrait registre bapteme Paris 16.pdf',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth Anna',
			type: 'baptism',
			typeExtra: 'extrait_registre',
			year: '1890/08/03',
			location: 'Paris 16'
		},
		{
			url: 'D19010502_Salvage marguerite elisabeth acte de 1ere communion Paris 16.jpg',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'first_communion',
			year: '1901/05/02',
			location: 'Paris 16'
		},
		{
			url: 'D18950205_Salvage antoine acte de deces Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'death',
			year: '1895/02/05',
			location: 'Paris 05'
		},
		{
			url: 'D18950207_Salvage antoine acte inhumation cimetiere Montparnasse Paris 14.jpg',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'death',
			typeExtra: 'inhumation_cimetiere',
			year: '1895/02/07',
			location: 'Paris 14'
		},
		{
			url: 'D19020405_Salvage antoine Boulet francoise acte de succession Paris 06.pdf',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'inheritage',
			year: '1902/04/05',
			location: 'Paris 06'
		},
		{
			url: 'D19020405_Boulet francoise Salvage antoine acte de succession Paris 06.pdf',
			lastName: 'Boulet',
			firstName: 'Françoise',
			type: 'inheritage',
			year: '1902/04/05',
			location: 'Paris 06'
		},
		{
			url: 'D19011026_Boulet francoise acte inhumation cimetiere Montparnasse Paris 14.jpg',
			lastName: 'Boulet',
			firstName: 'Françoise',
			type: 'death',
			typeExtra: 'inhumation_cimetiere',
			year: '1901/10/26',
			location: 'Paris 14'
		},
		{
			url: 'D18581010_Salvage guillaume henri acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'birth',
			year: '1858/10/10',
			location: 'Chamalières Lavastrie'
		},
		{
			url: 'D19130508_Gervaiseau leon elie Salvage marguerite elisabeth acte livret de famille Paris 14.pdf',
			family: 'gervaiseau',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'family',
			year: '1913/05/08',
			location: 'Paris 14'
		},
		{
			url: 'D19130508_Salvage marguerite elisabeth Gervaiseau leon elie acte livret de famille Paris 14.pdf',
			family: 'gervaiseau',
			lastName: 'Gervaiseau',
			firstName: 'Léon Elie',
			type: 'family',
			year: '1913/05/08',
			location: 'Paris 14'
		},
		{
			url: 'D19130512_Gervaiseau leon elie Salvage marguerite elisabeth acte faire part benediction nuptiale Paris 14.pdf',
			lastName: 'Gervaiseau',
			firstName: 'Léon Elie',
			type: 'marriage',
			typeExtra: 'faire_part_benediction_nuptiale',
			year: '1913/05/12',
			location: 'Paris 14'
		},
		{
			url: 'D19130512_Salvage marguerite elisabeth Gervaiseau leon elie acte faire part benediction nuptiale Paris 14.pdf',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'marriage',
			typeExtra: 'faire_part_benediction_nuptiale',
			year: '1913/05/12',
			location: 'Paris 14'
		},
		{
			url: 'D17940827_Salvage durand acte de naissance Chamalieres Lavastrie 15 v1.jpg',
			lastName: 'Salvage',
			firstName: 'Durand',
			type: 'birth',
			typeExtra: 'v1',
			year: '1794/08/27',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D17940827_Salvage durand acte de naissance Chamalieres Lavastrie 15 v2.jpg',
			lastName: 'Salvage',
			firstName: 'Durand',
			type: 'birth',
			typeExtra: 'v2',
			year: '1794/08/27',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D17971120_Bigot marie Chanson joseph acte de mariage Alleuze 15.jpg',
			lastName: 'Bigot',
			firstName: 'Marie',
			type: 'marriage',
			year: '1797/11/20',
			location: 'Alleuze 15'
		},
		{
			url: 'D17971120_Chanson joseph Bigot marie acte de mariage Alleuze 15.jpg',
			lastName: 'Chanson',
			firstName: 'Joseph',
			type: 'marriage',
			year: '1797/11/20',
			location: 'Alleuze 15'
		},
		{
			url: 'D17990731_Salvage jean acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jean',
			type: 'birth',
			year: '1799/07/31',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D17990819_Salvage jean acte de deces Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jean',
			type: 'death',
			year: '1799/08/19',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18090830_Chanson anne acte de naissance Alleuze 15.jpg',
			lastName: 'Chanson',
			firstName: 'Anne',
			type: 'birth',
			year: '1809/08/30',
			location: 'Alleuze 15'
		},
		{
			url: 'D18200202_Chanson anne Salvage durand acte de mariage Alleuze 15.jpg',
			lastName: 'Chanson',
			firstName: 'Anne',
			type: 'marriage',
			year: '1820/02/02',
			location: 'Alleuze 15'
		},
		{
			url: 'D18200202_Salvage durand Chanson anne acte de mariage Alleuze 15.jpg',
			lastName: 'Salvage',
			firstName: 'Durand',
			type: 'marriage',
			year: '1820/02/02',
			location: 'Alleuze 15'
		},
		{
			url: 'D18210913_Salvage joseph acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Joseph',
			type: 'birth',
			year: '1821/09/13',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18351106_Salvage marguerite acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Marguerite',
			type: 'birth',
			year: '1835/11/06',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18390418_Salvage jean acte de deces Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jean',
			type: 'death',
			year: '1839/04/18',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18620910_Salvage marie antoinette acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Antoinette',
			type: 'birth',
			year: '1862/09/10',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18641124_Salvage antoinette acte de naissance Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Antoinette',
			type: 'birth',
			year: '1864/11/24',
			location: 'Paris 05'
		},
		{
			url: 'D18691219_Salvage louise acte de naissance Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Louise',
			type: 'birth',
			year: '1869/12/19',
			location: 'Paris 05'
		},
		{
			url: 'D18731017_Salvage pierre louis acte de naissance Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Pierre Louis',
			type: 'birth',
			year: '1873/10/17',
			location: 'Paris 05'
		},
		{
			url: 'D18201017_Salvage gilbert acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Gilbert',
			type: 'birth',
			year: '1820/10/17',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18201028_Salvage gilbert acte de deces Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Gilbert',
			type: 'death',
			year: '1820/10/28',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18310204_Salvage gilbert acte de deces Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Gilbert',
			type: 'death',
			year: '1831/02/04',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18250923_Salvage jean acte de naisssance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jean',
			type: 'birth',
			year: '1825/09/23',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18280623_Salvage jean acte de naisssance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jean',
			type: 'birth',
			year: '1828/06/23',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18321005_Salvage antoine acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'birth',
			year: '1832/10/05',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18561123_Boulet francoise Salvage antoine acte publication des bans Chamalieres Lavastrie 15.jpg',
			lastName: 'Boulet',
			firstName: 'Françoise',
			type: 'bans_marriage',
			year: '1856/11/23',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18561123_Salvage antoine Boulet francoise acte publication des bans Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'bans_marriage',
			year: '1856/11/23',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18561126_Boulet francoise Salvage antoine acte de mariage Chamalieres Lavastrie 15.jpg',
			lastName: 'Boulet',
			firstName: 'Françoise',
			type: 'marriage',
			year: '1856/11/26',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18561126_Salvage antoine Boulet francoise acte de mariage Chamalieres Lavastrie 15.jpg',
			lastName: 'Salvage',
			firstName: 'Antoine',
			type: 'marriage',
			year: '1856/11/26',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18720417_Salvage louise acte de deces Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Louise',
			type: 'death',
			year: '1872/04/17',
			location: 'Paris 05'
		},
		{
			url: 'D18720418_Salvage louise acte inhumation cimetiere Montparnasse Paris 14.jpg',
			lastName: 'Salvage',
			firstName: 'Louise',
			type: 'death',
			typeExtra: 'inhumation_cimetiere',
			year: '1872/04/18',
			location: 'Paris 14'
		},
		{
			url: 'D19341012_Salvage antoinette acte de deces Paris 09.jpg',
			lastName: 'Salvage',
			firstName: 'Antoinette',
			type: 'death',
			year: '1934/10/12',
			location: 'Paris 09'
		},
		{
			url: 'D19410309_Salvage pierre louis acte de deces Paris 14.jpg',
			lastName: 'Salvage',
			firstName: 'Pierre Louis',
			type: 'death',
			year: '1941/03/09',
			location: 'Paris 14'
		},
		{
			url: 'D18030718_Portefaix anne acte de naissance Chamalieres Lavastrie 15.jpg',
			lastName: 'Portefaix',
			firstName: 'Anne',
			type: 'birth',
			year: '1803/07/18',
			location: 'Chamalières Lavastrie 15'
		},
		{
			url: 'D18660211_Coulmeau eugenie clementine acte de naissance Ecoman 41.jpg',
			lastName: 'Coulmeau',
			firstName: 'Eugénie Clémentine',
			type: 'birth',
			year: '1866/02/11',
			location: 'Ecoman 41'
		},
		{
			url: 'D18760325_Zele henriette jeanne acte de naisssance Paris 18.jpg',
			lastName: 'Zèle',
			firstName: 'Henriette Jeanne',
			type: 'birth',
			year: '1876/03/25',
			location: 'Paris 18'
		},
		{
			url: 'D18930604_Salvage suzanne antoinette acte de naissance Paris 18.jpg',
			lastName: 'Salvage',
			firstName: 'Suzanne Antoinette',
			type: 'birth',
			year: '1893/06/04',
			location: 'Paris 18'
		},
		{
			url: 'D18160131_Bobin charles Podevin marie acte de mariage Mauleon 79.jpg',
			lastName: 'Bobin',
			firstName: 'Charles',
			type: 'marriage',
			year: '1816/01/31',
			location: 'Mauléon 79'
		},
		{
			url: 'D18160131_Podevin marie Bobin charles acte de mariage Mauleon 79.jpg',
			lastName: 'Podevin',
			firstName: 'Marie',
			type: 'marriage',
			year: '1816/01/31',
			location: 'Mauléon 79'
		},
		{
			url: 'D18470112_Bobin charles francois Quillet jeanne acte de mariage Saint-Aubin-de-Baubigne 79.jpg',
			lastName: 'Bobin',
			firstName: 'Charles François',
			type: 'marriage',
			year: '1847/01/12',
			location: 'Saint-Aubin-de-Baubigné 79'
		},
		{
			url: 'D18470112_Quillet jeanne Bobin charles francois acte de mariage Saint-Aubin-de-Baubigne 79.jpg',
			lastName: 'Quillet',
			firstName: 'Jeanne',
			type: 'marriage',
			year: '1847/01/12',
			location: 'Saint-Aubin-de-Baubigné 79'
		},
		{
			url: 'D18530522_Bobin constant charles acte de naissance Mauleon 79.jpg',
			lastName: 'Bobin',
			firstName: 'Constant Charles',
			type: 'birth',
			year: '1853/05/22',
			location: 'Mauléon 79'
		},
		{
			url: 'D18850521_Bobin constant charles Salvage marie antoinette acte de mariage Paris 05.jpg',
			lastName: 'Bobin',
			firstName: 'Constant Charles',
			type: 'marriage',
			year: '1885/05/21',
			location: 'Paris 05'
		},
		{
			url: 'D18850521_Salvage marie antoinette Bobin constant charles acte de mariage Paris 05.jpg',
			lastName: 'Salvage',
			firstName: 'Marie Antoinette ',
			type: 'marriage',
			year: '1885/05/21',
			location: 'Paris 05'
		},
		{
			url: 'D19310212_Coulmeau eugenie clementine acte de deces Paris 16.jpg',
			lastName: 'Coulmeau',
			firstName: 'Eugénie Clémentine',
			type: 'death',
			year: '1931/02/12',
			location: 'Paris 16'
		},
		{
			url: 'D19380920_Salvage guillaume henri acte de deces Paris 16.jpg',
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'death',
			year: '1938/09/20',
			location: 'Paris 16'
		},
		{
			url: 'D19410124_Zele henri xavier acte de deces Paris 14.jpg',
			lastName: 'Zèle',
			firstName: 'Henri Xavier',
			type: 'death',
			year: '1941/01/24',
			location: 'Paris 14'
		},
		{
			url: 'D18870911_Bobin charles francois acte de deces Chatillon-sur-Seine Mauleon 79.jpg',
			lastName: 'Bobin',
			firstName: 'Charles François',
			type: 'death',
			year: '1887/09/11',
			location: 'Chatillon-sur-Seine Mauléon 79'
		},
		{
			url: 'D18661011_Salvage jeanne acte de naissance Paris 05.pdf',
			lastName: 'Salvage',
			firstName: 'Jeanne',
			type: 'birth',
			year: '1866/10/11',
			location: 'Paris 05'
		},
		{
			url: 'D19210530_Salvage marguerite elisabeth acte pension veuve de guerre Paris 14.pdf',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'pension_veuve_de_guerre',
			year: '1921/05/30',
			location: 'Paris 14'
		},
		{
			url: 'D19220420_Bernard jeanne Salvage maurice acte faire part benediction nuptiale Arcueil 94.pdf',
			lastName: 'Bernard',
			firstName: 'Jeanne',
			type: 'marriage',
			typeExtra: 'faire_part_benediction_nuptiale',
			year: '1922/04/20',
			location: 'Arcueil 94'
		},
		{
			url: 'D19220420_Salvage maurice Bernard jeanne acte faire part benediction nuptiale Arcueil 94.pdf',
			lastName: 'Salvage',
			firstName: 'Maurice',
			type: 'marriage',
			typeExtra: 'faire_part_benediction_nuptiale',
			year: '1922/04/20',
			location: 'Arcueil 94'
		},
		{
			url: 'D19290305_Zele henriette acte faire part de deces Paris.jpg',
			lastName: 'Zelé',
			firstName: 'Henriette',
			type: 'death',
			typeExtra: 'faire_part_deces',
			year: '1929/03/05',
			location: 'Paris'
		},
		{
			url: 'D19310212_Coulmeau eugenie clementine acte faire part de deces Paris 16.pdf',
			lastName: 'Coulmeau',
			firstName: 'Eugénie Clémentine',
			type: 'death',
			typeExtra: 'faire_part_deces',
			year: '1931/02/12',
			location: 'Paris16'
		},
		{
			url: "D19360331_Salvage guillaume henri acte carte d'electeur Paris 16.pdf",
			lastName: 'Salvage',
			firstName: 'Guillaume Henri',
			type: 'carte_electeur',
			year: '1936/03/31',
			location: 'Paris16'
		},
		{
			url: 'D19651202_Salvage marguerite elisabeth acte brevet pension de veuve de guerre Bondy 94.pdf',
			lastName: 'Salvage',
			firstName: 'Marguerite Elisabeth',
			type: 'brevet_pension_veuve_de_guerre',
			year: '1965/12/02',
			location: 'Bondy 94'
		},
		{
			url: 'D19731013_Illig robert acte faire part obseques Paris 15.pdf',
			lastName: 'Illig',
			firstName: 'Robert',
			type: 'death',
			typeExtra: 'faire_part_obseques',
			year: '1931/02/12',
			location: 'Paris15'
		},
		{
			url: 'D19810202_Salvage germaine acte faire part faire part obseques Paris 15.pdf',
			lastName: 'Salvage',
			firstName: 'Germaine',
			type: 'death',
			typeExtra: 'faire_part_obseques',
			year: '1981/02/02',
			location: 'Paris15'
		},
		{
			url: 'D19880808_Bernard jeanne faire part deces La Loupe 28.pdf',
			lastName: 'Bernard',
			firstName: 'Jeanne',
			type: 'death',
			typeExtra: 'faire_part_deces',
			year: '1988/08/08',
			location: 'La Loupe 28'
		},
		{
			url: 'D19990418_Salvage marguerite elisabeth acte faire part de deces Paris 18.pdf',
			lastName: 'Salvage',
			firstName: 'Margerite Elisabeth',
			type: 'death',
			typeExtra: 'faire_part_deces',
			year: '1999/04/18',
			location: 'Paris18'
		},
		{
			url: 'D19900626_Salvage marguerite elisabeth acte de succession Paris 18.pdf',
			lastName: 'Salvage',
			firstName: 'Margerite Elisabeth',
			type: 'inheritage',
			year: '1990/06/26',
			location: 'Paris18'
		},
		{
			url: 'D19970806_Salvage jeanne faire acte part de deces Paris 15.jpg',
			lastName: 'Salvage',
			firstName: 'Jeanne',
			type: 'death',
			typeExtra: 'faire_part_deces',
			year: '1997/08/06',
			location: 'Paris15'
		},
		{
			url: 'D19770531_Salvage suzanne antoinette extrait minutes acte de naissance Paris 18.pdf',
			lastName: 'Salvage',
			firstName: 'Suzanne Antoinette',
			type: 'birth',
			typeExtra: 'extrait_minutes',
			year: '1977/05/31',
			location: 'Paris 18'
		}
	]
}

export default Documents

const x = 250;
const y = 200;

const families = () => {
	return [
		{
			id: 'dupont',
			color: '#ff6f69',
			label: 'Dupont',
			title: 'Origine Manche/Calvados',
			x: 0,
			y: 0
		},
		{
			id: 'urbain',
			color: '#ffeead',
			label: 'Urbain',
			title:
				'par Hortense Urbain<br>Épouse Jacques Alexandre Dupont<br>Origine Calvados',
			x: x,
			y: -y
		},
		{
			id: 'capey',
			color: '#ffaaa5',
			label: 'Capey',
			title: 'par Thérèse Capey<br>Épouse Fernand Dupont<br>Origine Manche',
			x: -x,
			y: -y
		},
		{
			id: 'beckx',
			color: '#ffcc5c',
			label: 'Beckx',
			title: 'par Louise Beckx<br>Épouse Jacques Dupont<br>Origine Belgique',
			x: -x,
			y: y
		},
		{
			id: 'fontaine',
			color: '#88d8b0',
			label: 'Fontaine',
			title: 'par Marcel Fontaine<br>Époux Thérèse Dupont<br>Origine Oise',
			x: 0,
			y: y
		},
		{
			id: 'toutard',
			color: '#8b9dc3',
			label: 'Toutard',
			title: 'par Geniève Toutard<br>Épouse Jacques Fontaine<br>Origine Vienne',
			x: -x,
			y: y * 2
		},
		{
			id: 'potut',
			color: '#ff8b94',
			label: 'Potut',
			title:
				'par Gaston Potut<br>Deuxième époux de Marguerite Salvage<br>Origine Charente',
			x: x * 0.5,
			y: y * 2
		},
		{
			id: 'coulmeau',
			color: '#f2a6fc',
			label: 'Coulmeau',
			title:
				'par Eugénie Coulmeau<br>Épouse (Guillaume) Henri Salvage<br>Origine Loir et Cher',
			x: x * 3.5,
			y: y * 2
		},
		{
			id: 'gervaiseau',
			color: '#ffefd7',
			label: 'Gervaiseau',
			title:
				'par Suzanne léone Gervaiseau<br>Fille de Léon Gervaiseau<br>Épouse Louis Dupont<br>Origine Sarthe',
			x: x,
			y: y
		},
		{
			id: 'salvage',
			color: '#d6eaff',
			label: 'Salvage',
			title:
				'par Marguerite Salvage<br>Épouse Léon Gervaiseau et Mère de Suzanne Léone Gervaiseau<br>Épouse Louis Dupont<br>Origine Puy-de-Dôme',
			x: x * 2,
			y: y * 2
		}
	];
};

export default families;

import React from 'react'
import { getDocument, trackLink } from '../Firebase/Firebase'

import { Search } from 'semantic-ui-react'
import searchUsers from '../../data/searchUsers'
import documentsData from '../../data/documentsData'

import './SearchBar.css'

class SearchBar extends React.Component {
	state = { search: '', users: [] }

	onInuputChange = e => {
		this.setState({ search: e.target.value })
		this.filterResults(e.target.value)
	}

	openUserAct = (e, { result }) => {
		let document

		Object.keys(documentsData()).forEach(key => {
			documentsData()[key].forEach(doc => {
				if (
					doc.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') ===
					result.description.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
				)
					document = doc
			})
		})

		if (document) this.getDocument(document)
	}

	getDocument = async doc => {
		const document = await getDocument(doc.path, doc.url)
		window.open(document)
		trackLink(doc.id)
	}

	filterResults = input => {
		let users = []

		searchUsers().forEach((user, index) => {
			users.push({ key: index, title: user.A, description: user.B })
		})

		if (!input || input === '') return users

		users = users.filter(v => {
			return (
				v &&
				v.title
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.indexOf(
						input
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '')
					) > -1
			)
		})

		this.setState({ users: users })
	}

	render() {
		return (
			<div className="search-container">
				<Search
					placeholder="Recherche nom/prénom"
					onResultSelect={this.openUserAct}
					onSearchChange={this.onInuputChange}
					results={this.state.users}
					value={this.state.search}
				/>
			</div>
		)
	}
}

export default SearchBar

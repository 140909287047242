import React from 'react'
import { getDocument } from '../../components/Firebase/Firebase'

class ActLink extends React.Component {
	state = { openUrl: '_blank' }

	componentDidMount() {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		if (isMobile) this.setState({ openUrl: '' })
	}

	getDocument = async () => {
		const document = await getDocument(this.props.path, this.props.url)
		window.open(document, this.state.openUrl)
	}

	render() {
		return (
			<div>
				<a href="javascript:;" onClick={this.getDocument} rel="noopener noreferrer">
					<i className="eye icon" />
				</a>
			</div>
		)
	}
}

export default ActLink

import React from 'react';
import { Marker } from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

import familiesData from '../../data/familiesData';

class InfoWindowMarker extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: true
		};
		this.onToggleOpen = this.onToggleOpen.bind(this);
	}

	onToggleOpen = () => {
		this.props.markerClick(this.props.coords);
		// this.setState({
		// 	isOpen: !this.state.isOpen
		// });
	};

	getFamiliesColor = () => {
		return familiesData().find(
			family => family.id === this.props.family.toLowerCase()
		).color;
	};

	render() {
		return (
			<Marker position={this.props.coords} onClick={this.onToggleOpen}>
				{this.state.isOpen && this.props.zoom < 9 && (
					<InfoBox
						defaultPosition={this.props.coords}
						options={{
							pane: 'overlayLayer',
							pixelOffset: this.props.offset || { width: -40, height: -40 },
							alignBottom: true,
							closeBoxURL: ''
						}}
					>
						<div
							className="map-infobox"
							style={{ backgroundColor: this.getFamiliesColor() }}
						>
							<div>{this.props.family}</div>
							<div className="infobox-place">{this.props.place}</div>
							<div className="infobox-place">{this.props.department}</div>
						</div>
					</InfoBox>
				)}
			</Marker>
		);
	}
}

export default InfoWindowMarker;

import React from 'react';
import PdfLink from '../../../components/PdfLink';

import documentsData from '../../../data/documentsData';

const Gerouit = () => {
	return (
		<div className="content-container">
			<PdfLink
				title={documentsData().gerouit[0].title}
				path={documentsData().gerouit[0].path}
				url={documentsData().gerouit[0].url}
				id={documentsData().gerouit[0].id}
			/>
		</div>
	);
};

export default Gerouit;

import _ from 'lodash'
import Clipboard from 'clipboard'
import React from 'react'
import { connect } from 'react-redux'
import { Confirm } from 'semantic-ui-react'
import { signInOpen } from '../../actions/auth'
import {
	getTracks,
	getAllUsers,
	deleteUser,
	getNewsText,
	updateNewsText,
	sendBatchEmails
} from '../../components/Firebase/Firebase'
import Header from '../../components/Header/Header'
import Spinner from '../../components/Spinner'
import AuthModal from '../../components/auth/AuthModal'

import documentsData from '../../data/documentsData'

class Dashboard extends React.Component {
	state = {
		tracks: [],
		loading: true,
		button: 'primary',
		segment: 'acts',
		users: [],
		confirm: false,
		userId: null,
		index: null,
		text: '',
		textLoading: false,
		emailSubject: '',
		emailMessage: '',
		sending: false
	}
	documents = [].concat.apply([], _.values(documentsData()))

	async componentDidMount() {
		const text = await getNewsText()
		this.setState({ text })

		let tracks = await getTracks()

		// tracks.forEach(track => {
		// 	track.users.sort((a, b) => {
		// 		if (b.info && a.info) {
		// 			return b.info.timestamp.seconds - a.info.timestamp.seconds;
		// 		} else {
		// 			return b - a;
		// 		}
		// 	});
		// });

		const formattedTracks = []

		tracks.forEach(track => {
			track.users.forEach(user => {
				formattedTracks.push({ ...user, ...{ pageId: track.id } })
			})
		})

		formattedTracks.sort((a, b) => {
			if (b.info && b.info.timestamp && a.info && a.info.timestamp) {
				return b.info.timestamp.seconds - a.info.timestamp.seconds
			} else {
				return b - a
			}
		})

		new Clipboard('#export-btn').on('success', function (e) {
			e.clearSelection()
		})

		let users = await getAllUsers()

		this.setState({ tracks: formattedTracks, users, loading: false })
	}

	renderActsTable() {
		return (
			<>
				<thead>
					<tr>
						<th>Date</th>
						<th>Email</th>
						<th>IP</th>
						<th>Page</th>
						<th>Vues</th>
					</tr>
				</thead>
				<tbody>{this.renderTracks()}</tbody>
			</>
		)
	}

	getDocumentTitle(id) {
		const result = this.documents.find(doc => doc.id === id)
		if (result) return result.title + ' - PDF'
		else if (id === 'excel' || id === 'carte') return id
		else return 'Famille ' + id
	}

	renderTracks() {
		return this.state.tracks.map((track, index) => {
			return (
				<tr key={index}>
					<td data-label="Date">{this.renderUsers(track, 'timestamp')}</td>
					<td data-label="Email">{this.renderUsers(track, 'email')}</td>
					<td data-label="IP">{this.renderUsers(track, 'ip')}</td>
					<td data-label="Page" style={{ textTransform: 'capitalize' }}>
						<div key={track.userId}>{this.getDocumentTitle(track.pageId)}</div>
					</td>
					<td data-label="Vues">{this.renderUsers(track, 'count')}</td>
				</tr>
			)
		})
	}

	renderUsers(user, value) {
		let isoDate = ''
		if (user.info && user.info.timestamp) {
			const date = new Date(user.info.timestamp.seconds * 1000)
			isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
				.toISOString()
				.slice(0, 19)
				.replace('T', ' ')
		}

		return (
			<div>
				{value === 'email'
					? user.email
					: value === 'timestamp'
					? isoDate || ''
					: value === 'ip'
					? user.info.ip
					: user.info.count}
			</div>
		)
	}

	renderUsersTable() {
		return (
			<>
				<thead>
					<tr>
						<th>Email</th>
						<th className="center aligned">Action</th>
					</tr>
				</thead>
				<tbody>{this.renderAccounts()}</tbody>
			</>
		)
	}

	deleteAccount() {
		deleteUser(this.state.userId)
		const users = this.state.users
		users.splice(this.state.index, 1)
		this.setState({ users, confirm: false })
	}

	onClickDelete(userId, index) {
		this.setState({ userId, index, confirm: true })
	}

	renderAccounts() {
		return this.state.users.map((user, index) => {
			return (
				<tr key={user.id}>
					<td data-label="Email">{user.email}</td>
					<td data-label="Action" className="center aligned">
						<button
							className="ui red button"
							onClick={() => this.onClickDelete(user.id, index)}
						>
							Supprimer
						</button>
						<Confirm
							open={this.state.confirm}
							content="Attention ! Cette action est irréversible"
							cancelButton="Annuler"
							confirmButton="Supprimer"
							onCancel={() => this.setState({ confirm: false })}
							onConfirm={() => this.deleteAccount()}
						/>
					</td>
				</tr>
			)
		})
	}

	updateText = () => {
		this.setState({ textLoading: true })
		updateNewsText(this.state.text).then(() => {
			this.setState({ textLoading: false })
		})
	}

	onFormSubmit = async event => {
		event.preventDefault()

		if (!this.state.emailSubject.length || !this.state.emailMessage.length)
			return

		this.setState({ sending: true })

		await sendBatchEmails(this.state.emailSubject, this.state.emailMessage)

		this.setState({ sending: false })
		this.setState({ emailSubject: '' })
		this.setState({ emailMessage: '' })
	}

	renderEmail() {
		return (
			<form onSubmit={this.onFormSubmit} className="ui form container">
				<div className="field">
					<label>Titre*</label>
					<div className="ui icon input">
						<input
							type="text"
							placeholder="Entrez le titre du mail"
							autoComplete="nope"
							name="message"
							value={this.state.emailSubject}
							onChange={e => this.setState({ emailSubject: e.target.value })}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label>Message*</label>
					<div className="ui icon input">
						<textarea
							type="text"
							placeholder="Entrez le message du mail"
							autoComplete="nope"
							name="message"
							value={this.state.emailMessage}
							onChange={e => this.setState({ emailMessage: e.target.value })}
							required
						/>
					</div>
				</div>

				<div className="button-form">
					<button
						className={`ui button primary ${
							this.state.sending ? 'loading' : ''
						}`}
						type="submit"
					>
						Envoyer à Tous
					</button>
				</div>
			</form>
		)
	}

	renderTable() {
		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div>
				<button
					className={`ui ${this.state.button} button left floated`}
					id="export-btn"
					data-clipboard-target="#resultsTable"
					style={{ margin: '40px' }}
					onClick={() => this.setState({ button: 'green' })}
				>
					{this.state.button === 'primary' ? 'Copier' : 'Copié !'}
				</button>
				<button
					className="ui primary button right floated"
					style={{ margin: '40px' }}
					onClick={() =>
						this.setState({
							segment: this.state.segment !== 'users' ? 'users' : 'acts'
						})
					}
				>
					{this.state.segment === 'users' ? 'His. Connexions' : 'Utilisateurs'}
				</button>
				<button
					className="ui teal button right floated"
					style={{ margin: '40px 0' }}
					onClick={this.props.signInOpen}
				>
					Nouveau Compte
				</button>
				<button
					className="ui orange button right floated"
					style={{ margin: '40px' }}
					onClick={() =>
						this.setState({
							segment: 'emails'
						})
					}
				>
					Email à Tous
				</button>
				<AuthModal />

				<div className="table-container">
					<div style={{ margin: '20px 0' }}>
						<input
							placeholder="Description de la page d'accueil"
							autoComplete="nope"
							value={this.state.text}
							onChange={e => this.setState({ text: e.target.value })}
							className="input-description"
						/>
						<button
							className={`ui violet button right floated tiny ${
								this.state.textLoading ? 'loading' : ''
							}`}
							onClick={this.updateText}
						>
							Mettre à jour
						</button>
					</div>

					{this.state.segment === 'emails' && this.renderEmail()}

					{this.state.segment !== 'emails' && (
						<table
							id="resultsTable"
							className="ui celled striped unstackable table"
						>
							{this.state.segment === 'acts' && this.renderActsTable()}
							{this.state.segment === 'users' && this.renderUsersTable()}
						</table>
					)}
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				<Header title="Dashboard" />
				{this.renderTable()}
			</div>
		)
	}
}

// const mapStateToProps = state => {
// 	return {
// 		isSignedIn: state.auth.isSignedIn,
// 		currentUser: state.auth.user
// 	};
// };

export default connect(null, { signInOpen })(Dashboard)

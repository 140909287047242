const Documents = () => {
	return [
		{
			url: 'D18390915_Toutard eugene acte de naissance Charroux 86.jpg',
			lastName: 'Toutard',
			firstName: 'Eugène',
			type: 'birth',
			year: '1839/09/15',
			location: 'Charroux 86'
		},
		{
			url: 'D18680512_Toutard constant acte de naissance Charroux 86.jpg',
			lastName: 'Toutard',
			firstName: 'Constant',
			type: 'birth',
			year: '1868/05/12',
			location: 'Charroux 86'
		},
		{
			url: 'D19000110_Toutard eugene andre acte de naissance Chaunay 86.jpg',
			lastName: 'Toutard',
			firstName: 'Eugène André',
			type: 'birth',
			year: '1900/01/10',
			location: 'Chaunay 86'
		},
		{
			url: 'D19020210_Poirier Jeanne acte de naissance Paris 16.jpg',
			lastName: 'Poirier',
			firstName: 'Jeanne',
			type: 'birth',
			year: '1902/02/10',
			location: 'Paris 16'
		},
		{
			url: 'D19250711_Poirier jeanne acte de mariage table decennales Paris 18.jpg',
			lastName: 'Poirier',
			firstName: 'Jeanne',
			type: 'marriage',
			typeExtra: 'table_decennales',
			year: '1925/07/11',
			location: 'Paris 18'
		},
		{
			url: 'D19250711_Poirier jeanne Toutard eugene andre acte de mariage Paris 18.jpg',
			lastName: 'Poirier',
			firstName: 'Jeanne',
			type: 'marriage',
			year: '1925/07/11',
			location: 'Paris 12'
		},
		{
			url: 'D19250711_Toutard eugene andre acte de mariage table decennales Paris 18.jpg',
			lastName: 'Toutard',
			firstName: 'Eugène André',
			type: 'marriage',
			typeExtra: 'table_decennales',
			year: '1925/07/11',
			location: 'Paris 18'
		},
		{
			url: 'D19250711_Toutard eugene andre Poirier Jeanne acte de mariage Paris 18.jpg',
			lastName: 'Toutard',
			firstName: 'Eugène André',
			type: 'marriage',
			year: '1925/07/11',
			location: 'Paris 18'
		},
		{
			url: 'D19540128_Poirier jeanne acte de deces Paris 18.jpg',
			lastName: 'Poirier',
			firstName: 'Jeanne',
			type: 'death',
			year: '1954/01/28',
			location: 'Paris 18'
		},
		{
			url: 'D19570324_Moreau marie berthe acte de deces Paris 18.jpg',
			lastName: 'Moreau',
			firstName: 'Marie Berthe',
			type: 'death',
			year: '1957/03/24',
			location: 'Paris 18'
		}
	]
}

export default Documents

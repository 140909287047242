import React from 'react';
import { trackLink } from '../components/Firebase/Firebase';

import Header from '../components/Header/Header';
import Dupont from './familyDetail/families/Dupont';
import Urbain from './familyDetail/families/Urbain';
import Capey from './familyDetail/families/Capey';
import Beckx from './familyDetail/families/Beckx';
import Fontaine from './familyDetail/families/Fontaine';
import Toutard from './familyDetail/families/Toutard';
import Salvage from './familyDetail/families/Salvage';
import Potut from './familyDetail/families/Potut';
import Coulmeau from './familyDetail/families/Coulmeau';
import Gervaiseau from './familyDetail/families/Gervaiseau';
import Illig from './familyDetail/families/Illig';
import Zele from './familyDetail/families/Zele';
import Gerouit from './familyDetail/families/Gerouit';

class FamilyTree extends React.Component {
	componentDidMount() {
		trackLink(this.props.match.params.familyId);
	}

	displayFamily = title => {
		if (title === 'Dupont') {
			return <Dupont />;
		} else if (title === 'Urbain') {
			return <Urbain />;
		} else if (title === 'Capey') {
			return <Capey />;
		} else if (title === 'Beckx') {
			return <Beckx />;
		} else if (title === 'Fontaine') {
			return <Fontaine />;
		} else if (title === 'Toutard') {
			return <Toutard />;
		} else if (title === 'Salvage') {
			return <Salvage />;
		} else if (title === 'Potut') {
			return <Potut />;
		} else if (title === 'Coulmeau') {
			return <Coulmeau />;
		} else if (title === 'Gervaiseau') {
			return <Gervaiseau />;
		} else if (title === 'Illig') {
			return <Illig />;
		} else if (title === 'Zele') {
			return <Zele />;
		} else if (title === 'Gerouit') {
			return <Gerouit />;
		}
	};

	render() {
		if (!this.props.match) return <div />;

		const familyId = this.props.match.params.familyId;
		const familyName = familyId.charAt(0).toUpperCase() + familyId.slice(1);

		return (
			<div>
				<Header title={`Famille ${familyName}`} />
				<div>{this.displayFamily(familyName)}</div>
			</div>
		);
	}
}

export default FamilyTree;

import React from 'react'
import { Link } from 'react-router-dom'

const CivilAct = props => {
	return (
		<Link to={`/famille/${props.path}/documents`} className="text-medium">
			<i className="file alternate outline icon icon-color" />
			Actes Civils - Administratifs - Religieux - Photos
		</Link>
	)
}

export default CivilAct

import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import getLocationByAddress from '../../api/google-maps';
import { trackLink, getKml } from '../Firebase/Firebase';

import Header from '../Header/Header';
import './FamilyMap.css';

import InfoWindowMarker from './InfoWindowMarker';

let mapRef;

const getMapView = props => {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	const dupontOffset = isMobile
		? { width: -85, height: 15 }
		: { width: -125, height: 50 };

	const gervaiseauOffset = isMobile
		? { width: -80, height: 20 }
		: { width: -100, height: 65 };

	const capeyOffset = isMobile
		? { width: -40, height: -45 }
		: { width: -70, height: -45 };

	const fontaineOffset = isMobile
		? { width: -40, height: -45 }
		: { width: -80, height: -45 };

	const urbainOffset = isMobile
		? { width: -30, height: -45 }
		: { width: -50, height: -45 };

	const coulmeauOffset = isMobile
		? { width: 15, height: -25 }
		: { width: 10, height: -25 };

	const potutOffset = isMobile
		? { width: -60, height: 25 }
		: { width: -80, height: 55 };

	const salvageOffset = isMobile
		? { width: -30, height: 25 }
		: { width: 10, height: 40 };

	const toutardOffset = isMobile
		? { width: 15, height: -30 }
		: { width: 10, height: -30 };

	return (
		<GoogleMap
			zoom={props.zoom}
			defaultCenter={{ lat: 47.3, lng: 2.5 }}
			onZoomChanged={props.onZoomChanged}
			ref={map => (mapRef = map)}
		>
			<InfoWindowMarker
				coords={{ lat: 48.749393, lng: -0.849284 }}
				zoom={props.zoom}
				family="Dupont"
				place={isMobile ? '' : 'Saint-Sauveur de Chaulieu'}
				department={isMobile ? '' : 'Manche 50'}
				offset={dupontOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 48.064764, lng: 0.159493 }}
				zoom={props.zoom}
				family="Gervaiseau"
				place={isMobile ? '' : 'Saint-Saturnin'}
				department={isMobile ? '' : 'Sarthe 72'}
				offset={gervaiseauOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 49.185796, lng: -1.409685 }}
				zoom={props.zoom}
				family="Capey"
				place={isMobile ? '' : 'Périers'}
				department={isMobile ? '' : 'Manche 50'}
				offset={capeyOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 49.417575, lng: 2.827394 }}
				zoom={props.zoom}
				family="Fontaine"
				place={isMobile ? '' : 'Compiègne'}
				department={isMobile ? '' : 'Oise 60'}
				offset={fontaineOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 49.118782, lng: 6.177871 }}
				zoom={props.zoom}
				family="Urbain"
				place={isMobile ? '' : 'Metz'}
				department={isMobile ? '' : 'Moselle 57'}
				offset={urbainOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 47.832143, lng: 1.375975 }}
				zoom={props.zoom}
				family="Coulmeau"
				place={isMobile ? '' : 'St-Léonard-en-Beauce'}
				department={isMobile ? '' : 'Loir et Cher 41'}
				offset={coulmeauOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 45.720062, lng: 0.120513 }}
				zoom={props.zoom}
				family="Potut"
				place={isMobile ? '' : 'Vindelle'}
				department={isMobile ? '' : 'Charentes 16'}
				offset={potutOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 45.774013, lng: 3.059793 }}
				zoom={props.zoom}
				family="Salvage"
				place={isMobile ? '' : 'Chamalières'}
				department={isMobile ? '' : 'Puy-de-Dôme 63'}
				offset={salvageOffset}
				markerClick={props.markerClick}
			/>
			<InfoWindowMarker
				coords={{ lat: 46.185568, lng: 3.16404 }}
				zoom={props.zoom}
				family="Toutard"
				place={isMobile ? '' : 'Charroux'}
				department={isMobile ? '' : 'Vienne 86'}
				offset={toutardOffset}
				markerClick={props.markerClick}
			/>
			{/* <KmlLayer url={props.kml} options={{ preserveViewport: true }} /> */}
		</GoogleMap>
	);
};

const MapComponent = compose(
	withProps({
		googleMapURL:
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKBZoWLLha6GhccLZNBASkHlfnD-sPpjk&v=3.exp&libraries=geometry,drawing,places',
		loadingElement: <div style={{ height: '100%' }} />,
		containerElement: <div style={{ height: '80vh', padding: '20px' }} />,
		mapElement: <div style={{ height: '100%', borderRadius: '20px' }} />,
		center: { lat: 46.71667, lng: 2.51667 }
	}),
	withStateHandlers(
		() => ({
			isOpen: false
		}),
		{
			onToggleOpen: ({ isOpen }) => () => ({
				isOpen: !isOpen
			})
		}
	),
	withScriptjs,
	withGoogleMap
)(props => getMapView(props));

class MapContainer extends React.PureComponent {
	state = {
		isMarkerShown: false,
		coords: { lat: 46.71667, lng: 2.51667 },
		kml: '',
		zoom: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 5 : 6
	};

	componentDidMount() {
		this.delayedShowMarker();
		this.getMarker();
		this.getKml();
		trackLink('carte');
	}

	getKml = async () => {
		const kml = await getKml('Carte départements.kmz');
		this.setState({ kml: kml });
	};

	delayedShowMarker = () => {
		setTimeout(() => {
			this.setState({ isMarkerShown: true });
		}, 500);
	};

	getMarker = async () => {
		const coords = await getLocationByAddress(this.props.address);
		this.setState({ coords: coords });
	};

	markerClick = coords => {
		mapRef.panTo(coords);
		this.setState({ zoom: 11 });
	};

	onZoomChanged = () => {
		if (mapRef) this.setState({ zoom: mapRef.getZoom() });
	};

	render() {
		return (
			<div>
				<Header />
				<MapComponent
					isMarkerShown={this.state.isMarkerShown}
					coords={this.state.coords}
					street={this.props.street}
					kml={this.state.kml}
					markerClick={this.markerClick}
					zoom={this.state.zoom}
					onZoomChanged={this.onZoomChanged}
				/>
			</div>
		);
	}
}

export default MapContainer;
